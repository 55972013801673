<template>
  <v-dialog v-model="editDialog" persistent scrollable>
    <v-card>
      <v-card-title>edit content for {{ myId }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="10">
            <v-text-field
              disabled
              dense
              label="ID"
              v-model="create.id"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="create.data_type"
              label="data type"
              dense
              :items="dataTypes"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>

        <div v-if="create.data_type === 'text'">
          <v-text-field
            outlined
            dense
            label="data"
            v-model="create.data"
          ></v-text-field>
        </div>
        <div v-else-if="create.data_type === 'html'">
          <mavon-editor language="en" v-model="create.data" />
          <!-- <vue-editor
            v-model="create.data"
            :editor-toolbar="customToolbar"
          ></vue-editor> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
// import { VueEditor } from "vue2-editor";
import Utils from "../../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    editDialog: Boolean,
    myId: { type: String },
    myData: { type: Object },
    datid: { type: Number },
  },
  mixins: [Utils, validationMixin],
  // components: { VueEditor },
  data() {
    return {
      tabs: null,
      contentList: [],
      create: {
        id: this.myData.id,
        data: this.myData.data,
        data_type: this.myData.data_type,
      },
      dataTypes: [
        { name: "text", value: "text" },
        { name: "html", value: "html" },
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  validations: {
    create: {
      id: {
        required,
      },
      data: {
        required,
      },
      data_type: {
        required,
      },
    },
  },
  watch: {
    "create.data_type": function (a) {
      console.log("a", a);
      // if (a > 500000) {
      //   this.myPix = "Picture has to be less than 300KB (0.3MB) in size";
      //   this.disabled = true;
      // } else {
      //   this.disabled = false;
      // }
    },
  },
  created() {
    // this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.contentList = data.contents;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = true;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let id = this.create.id;
      let data = this.create.data;
      let data_type = this.create.data_type;
      let me = { id, data, data_type };

      let payload = {
        id: this.myId,
        pid: parseInt(this.datid),
        data: me,
        state: false,
      };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
