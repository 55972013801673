<template>
  <div>
    <v-dialog v-model="newDialog" scrollable persistent>
      <v-card>
        <v-card-title>new content on {{ name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="10">
              <v-text-field dense label="ID" v-model="create.id"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                v-model="create.data_type"
                label="data type"
                dense
                :items="dataTypes"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>

          <div v-if="create.data_type === 'text'">
            <v-text-field
              outlined
              dense
              label="data"
              v-model="create.data"
            ></v-text-field>
          </div>
          <div v-else-if="create.data_type === 'html'">
            <mavon-editor language="en" v-model="create.data" />
            <!-- <vue-editor
              v-model="create.data"
              :editor-toolbar="customToolbar"
            ></vue-editor> -->
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="red" v-on="on" @click="closeDialog">
                close
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="primary" v-on="on" @click="submit">
                submit
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Utils from "../../../mixins/utils";
// import { VueEditor } from "vue2-editor";

export default {
  mixins: [Utils, validationMixin],
  props: {
    newDialog: { type: Boolean },
    myId: { type: Number },
    name: { type: String },
  },
  components: {},
  data: () => ({
    pix: 0,
    myPix: null,
    disabled: true,
    customToolbar: [
      [{ header: [false, 1, 2, 3] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    dataTypes: [
      { name: "text", value: "text" },
      { name: "html", value: "html" },
    ],
    create: {
      id: null,
      data: null,
      data_type: null,
    },
  }),
  validations: {
    create: {
      id: {
        required,
      },
      data: {
        required,
      },
      data_type: {
        required,
      },
    },
  },
  watch: {
    "create.data_type": function (a) {
      console.log("a", a);
      // if (a > 500000) {
      //   this.myPix = "Picture has to be less than 300KB (0.3MB) in size";
      //   this.disabled = true;
      // } else {
      //   this.disabled = false;
      // }
    },
  },
  created() {},
  methods: {
    // changePix(e) {
    //   if (e != null) {
    //     console.log("e:", e);
    //     console.log("size:", e.size);
    //     this.pix = e.size;
    //     this.getPix(e).then((response) => {
    //       let pix = response;
    //       this.create.banner = pix;
    //       console.log(this.create.banner);
    //     });
    //   }
    // },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let id = this.create.id;
      let data = this.create.data;
      let data_type = this.create.data_type;
      let me = { id, data, data_type };

      let payload = { id: this.myId, data: me, state: false };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
