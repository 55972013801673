<template>
  <v-dialog v-model="viewDialog" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>{{ myData.id }}</v-card-title>
      <v-card-text>
        <div class="mybox">
          <div class="mydata">
            <!-- <div class="mylabel" v-html="myData.id"></div> -->
            <div class="datatype" v-text="myData.data_type"></div>
            <v-divider></v-divider>
            <div v-html="myData.data"></div>
          </div>
          <!-- <div class="actions">
            <v-btn icon color="info">
              <v-icon>mdi-circle-edit-outline</v-icon>
            </v-btn>

            <v-btn icon color="red">
              <v-icon>mdi-delete-empty-outline</v-icon>
            </v-btn>
          </div> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              close
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    myId: { type: String },
    myData: { type: Object },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        name: null,
        title: null,
        description: null,
        keywords: null,
        created: null,
        updated: null,
      },
    };
  },
  created() {
    // this.find();
  },
  methods: {
    // find() {
    //   this.loading = true;
    //   let self = this;
    //   Restful.cms.pages
    //     .find(this.myId)
    //     .then((response) => {
    //       console.log(response.data);
    //       let data = response.data;
    //       this.view.name = data.name;
    //       this.view.title = data.title;
    //       this.view.description = data.description;
    //       this.view.keywords = data.keywords;
    //       this.view.created = data.created;
    //       this.view.updated = data.updated;
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //       self.loading = true;
    //     });
    // },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
