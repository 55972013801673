<template>
  <v-dialog scrollable v-model="editDialog" persistent>
    <v-card>
      <v-card-title>edit {{ name }}</v-card-title>
      <v-card-text style="padding-top: 16px">
        <v-text-field
          dense
          disabled
          outlined
          label="name"
          v-model="view.name"
        ></v-text-field>
        <v-text-field
          dense
          outlined
          label="title"
          v-model="view.title"
        ></v-text-field>
        <v-text-field
          dense
          outlined
          label="description"
          v-model="view.description"
        ></v-text-field>

        <v-textarea
          dense
          outlined
          label="keywords"
          v-model="view.keywords"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    editDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String },
  },
  mixins: [validationMixin],
  data() {
    return {
      view: {
        name: null,
        title: null,
        keywords: null,
        description: null,
      },
    };
  },
  validations: {
    view: {
      name: {
        required,
      },
      title: {
        required,
      },
      keywords: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.view.name = data.name;
          this.view.title = data.title;
          this.view.keywords = data.keywords;
          this.view.description = data.description;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = true;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let name = this.view.name;
      let title = this.view.title;
      let keywords = this.view.keywords;
      let description = this.view.description;
      let data = { name, title, keywords, description };

      let payload = { id: this.myId, data: data, state: false };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
