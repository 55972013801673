<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>web tags</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              x-small
              text
              v-on="on"
              @click.stop="$set(editDialog, myId, true)"
            >
              edit tags on {{ name }}
            </v-btn>
          </template>
          <span>edit tags on {{ name }} </span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
    <PageEdit
      v-if="editDialog[myId]"
      :myId="myId"
      :name="name"
      :editDialog="editDialog[myId]"
      @closeEdit="closeEdit"
      @submitEdit="submitEdit"
    />

    <v-card outlined style="margin-bottom: 12px">
      <v-card-title>Title</v-card-title>
      <v-divider></v-divider>
      <v-card-text>{{ myDetail.title }}</v-card-text>
    </v-card>
    <v-card outlined style="margin-bottom: 12px">
      <v-card-title>description</v-card-title>
      <v-divider></v-divider>
      <v-card-text>{{ myDetail.description }}</v-card-text>
    </v-card>
    <v-card outlined>
      <v-card-title>keywords</v-card-title>
      <v-divider></v-divider>
      <v-card-text>{{ myDetail.keywords }}</v-card-text>
    </v-card>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import PageEdit from "../../components/cms/meta/edit.vue";

export default {
  props: {
    myId: { type: Number },
    name: { type: String },
  },
  components: { PageEdit },
  data: () => ({
    editDialog: {},
    myDetail: {},
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .find(this.myId)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.myDetail = response.data;
            this.loading = false;
          } else if (response.status === 204) {
            this.myDetail = {};
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          self.loading = false;
        });
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log("p:", p);
      this.snack.bar = true;
      let self = this;
      Restful.cms.pages
        // id, name, data
        .updateMeta(p.id, p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
  },
};
</script>
