<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" :to="{ name: 'admin.cms' }">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Back to Pages</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat>
          <v-toolbar dense style="padding: 0 16px" flat>
            <v-toolbar-title style="font-weight: 600">
              {{ name | capitalize }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- <PageNew
              :myId="parseInt(myId)"
              :name="name"
              :newDialog="newDialog"
              @closeNew="closeNew"
              @submitNew="submitNew"
            /> -->
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-tabs v-model="tab" background-color="transparent" centered>
              <v-tab key="meta">meta tags</v-tab>
              <v-tab key="contents">contents</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="meta">
                <PageMeta :myId="myId" :name="name" />
              </v-tab-item>
              <v-tab-item key="contents">
                <PageContent :myId="myId" :name="name" />
              </v-tab-item>
            </v-tabs-items>

            <!-- <v-data-table
              :loading="loading"
              loader-height="2"
              :headers="headers"
              :items="detailist"
              :items-per-page="8"
              :sort-by="['id']"
              :sort-desc="[false]"
              :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.identification="{ item }">
                {{ item.identification.toLowerCase() }}
              </template>
              <template v-slot:item.created="{ item }">
                {{ item.created | myDating }}
              </template>
              <template v-slot:item.updated="{ item }">
                {{ item.updated | myDating }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="info"
                      text
                      icon
                      v-on="on"
                      @click.stop="$set(viewDialog, item.id, true)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <PageView
                  v-if="viewDialog[item.id]"
                  :myId="item.id"
                  :name="item.identification"
                  :viewDialog="viewDialog[item.id]"
                  @closeView="closeView"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="success"
                      text
                      icon
                      v-on="on"
                      @click.stop="$set(editDialog, item.id, true)"
                    >
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <PageEdit
                  v-if="editDialog[item.id]"
                  :myId="item.id"
                  :name="item.identification"
                  :editDialog="editDialog[item.id]"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="red"
                      text
                      icon
                      v-on="on"
                      @click.stop="$set(removeDialog, item.id, true)"
                    >
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <PageRemove
                  v-if="removeDialog[item.id]"
                  :myId="item.id"
                  :name="item.identification"
                  :removeDialog="removeDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />
              </template>
            </v-data-table> -->
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
// import PageNew from "../../components/cms/pagedetails/newone.vue";
import PageMeta from "../cms/pages.meta.vue";
import PageContent from "../cms/pages.content.vue";
// import PageView from "../../components/cms/pagedetails/view.vue";
// import PageEdit from "../../components/cms/pagedetails/edit.vue";
// import PageRemove from "../../components/cms/pagedetails/remove.vue";

export default {
  name: "Pages",
  props: {
    myId: { type: Number },
    name: { type: String },
  },
  components: {
    // PageNew,
    PageMeta,
    PageContent,
    // PageView,
    //  PageEdit,
    //  PageRemove
  },
  data: () => ({
    newDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    detailist: {},
    breadcrumb: [
      {
        text: "Dashboard",
        disabled: false,
        to: { name: "admin.dash" },
      },
      {
        text: "Web Pages",
        exact: true,
        disabled: false,
        to: { name: "admin.cms" },
      },
      {
        text: "Page",
        exact: true,
        to: { name: "admin.cms.pages" },
      },
    ],
    tab: null,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
  }),
  created() {
    // this.detail();
  },
  methods: {
    // detail() {
    //   this.loading = true;
    //   let self = this;
    //   Restful.cms.pages
    //     .find(this.myId)
    //     .then((response) => {
    //       console.log(response);
    //       if (response.status === 200) {
    //         this.detailist = response.data;
    //         this.loading = false;
    //       } else if (response.status === 204) {
    //         this.detailist = {};
    //         this.loading = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //       console.log(error.response.data);
    //       self.loading = false;
    //     });
    // },
    refresh() {
      this.detail();
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log("p", p);
      this.snack.bar = true;
      let self = this;
      Restful.cms.detail
        // id, name, data
        .create(p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.loading = false;
          this.detail();
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.cms.detail
        // id, name, data
        .update(p.id, p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.detail();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });

      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      let self = this;
      Restful.cms.detail
        // id, name, data
        .delete(p.id)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.detail();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>

<style scoped></style>
