<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>content list</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="info"
              x-small
              text
              v-on="on"
              @click.stop="$set(newDialog, myId, true)"
            >
              new content to {{ name }}
            </v-btn>
          </template>
          <span>New content to {{ name }} </span>
        </v-tooltip>
        <ContentNew
          v-if="newDialog[myId]"
          :myId="myId"
          :name="name"
          :newDialog="newDialog[myId]"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-btn icon color="primary" @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div class="conlist">
      <v-data-table
        calculate-widths
        :loading="loading"
        loader-height="2"
        :items="myDetail"
        :headers="headers"
        :sort-by="['id']"
        :sort-desc="[false]"
        :items-per-page="8"
        :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
      >
        <template v-slot:item.data="{ item }">
          {{ truncateString(item.data, 110) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="$set(viewDialog, item.id, true)"
                x-small
                text
                color="primary"
                v-on="on"
              >
                view
              </v-btn>
            </template>
            <span>View {{ item.id }}</span>
          </v-tooltip>
          <ContentView
            v-if="viewDialog[item.id]"
            :myId="item.id"
            :myData="item"
            :viewDialog="viewDialog[item.id]"
            @closeView="closeView"
          />

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                color="success"
                v-on="on"
                @click.stop="$set(editDialog, item.id, true)"
              >
                edit
              </v-btn>
            </template>
            <span>Edit {{ item.id }}</span>
          </v-tooltip>
          <ContentEdit
            v-if="editDialog[item.id]"
            :myId="item.id"
            :myData="item"
            :datid="myId"
            :editDialog="editDialog[item.id]"
            @closeEdit="closeEdit"
            @submitEdit="submitEdit"
          />
        </template>
      </v-data-table>
    </div>

    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils.js";
import ContentNew from "../../components/cms/pages/newone.vue";
import ContentView from "../../components/cms/pages/view.vue";
import ContentEdit from "../../components/cms/pages/edit.vue";

export default {
  props: {
    myId: { type: Number },
    name: { type: String },
  },
  mixins: [Utils],
  components: { ContentNew, ContentView, ContentEdit },
  data: () => ({
    loading: false,
    viewDialog: {},
    myDetail: [],
    headers: [
      { text: "id", value: "id" },
      { text: "type", value: "data_type" },
      { text: "data", value: "data" },
      { text: "actions", value: "actions", align: "right" },
    ],
    newDialog: {},
    editDialog: {},
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .find(this.myId)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.myDetail = response.data.contents;
            this.loading = false;
          } else if (response.status === 204) {
            this.myDetail = [];
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          self.loading = false;
        });
    },
    refresh() {
      this.find();
    },
    closeNew(p) {
      this.$set(this.newDialog, p.id, p.state);
    },
    submitNew(p) {
      this.snack.bar = true;
      let self = this;
      Restful.cms.pages
        // id, name, data
        .addContent(p.id, p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.$set(this.newDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log("p", p);
      this.snack.bar = true;
      let self = this;
      Restful.cms.pages
        // id, name, data
        .editContent(p.pid, p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
  },
};
</script>
